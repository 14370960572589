.fc-toolbar-title {
    text-transform: capitalize;
}

.fc-scrollgrid-section-header {
    text-transform: capitalize;
}

.fc-timegrid-slot {
    cursor: pointer;
}

.fc-highlight {
    cursor: pointer;
}

.fc-daygrid-day-frame {
    min-height: 100%;
    position: relative;
    cursor: pointer;
}
